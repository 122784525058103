import * as React from "react"
import Layout from "../components/layout"
import NOT_FOUND from "../images/404.png";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="w-full flex justify-center items-center">
        <img src={NOT_FOUND} />
      </div>
    </Layout>
  )
}

export default NotFoundPage
